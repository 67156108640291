<button class="custom-button" 
        [ngClass]="{
          'primary': variant === 'primary',
          'secondary': variant === 'secondary',
          'disabled': disabled,
          'loading': loading
        }"
        [disabled]="disabled || loading"
        (click)="handleClick()">
  <div class="button-content" [class.hidden]="loading">
    <ng-content></ng-content>
  </div>
  <div class="spinner" *ngIf="loading"></div>
</button>