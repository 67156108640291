import {
          Renderer2,
          reflectComponentType,
          
  Component,
  ViewChild,
  ElementRef,
  Input,
  ViewContainerRef,
  TemplateRef,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";

export type InteractiveElementProps = {
  Wrapper: any;
  block: BuilderBlock;
  context: BuilderContextInterface;
  wrapperProps: Dictionary<any>;
  includeBlockProps: boolean;
  children?: any;
};

import type { BuilderContextInterface } from "../../../context/types";
import { getBlockActions } from "../../../functions/get-block-actions";
import { getBlockProperties } from "../../../functions/get-block-properties";
import type { BuilderBlock } from "../../../types/builder-block";
import type { Dictionary } from "../../../types/typescript";
import Awaiter from "../../awaiter";

@Component({
  selector: "interactive-element",
  template: `
    <ng-template #wrapperTemplate><ng-content></ng-content></ng-template>
    <ng-container *ngIf="Wrapper.load">
      <awaiter
        [load]="Wrapper.load"
        [fallback]="Wrapper.fallback"
        [props]="wrapperProps"
        [attributes]="attributes"
      >
        <ng-content></ng-content>
      </awaiter>
    </ng-container>
    <ng-container *ngIf="!(Wrapper.load)">
      <ng-container
        *ngComponentOutlet="
              Wrapper;
              inputs: mergedInputs_atkjh2;
              content: myContent;
              "
      ></ng-container>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, Awaiter],
})
export default class InteractiveElement {
  @Input() includeBlockProps!: InteractiveElementProps["includeBlockProps"];
  @Input() block!: InteractiveElementProps["block"];
  @Input() context!: InteractiveElementProps["context"];
  @Input() Wrapper!: InteractiveElementProps["Wrapper"];
  @Input() wrapperProps!: InteractiveElementProps["wrapperProps"];

  @ViewChild("wrapperTemplate", { static: true })
  wrapperTemplateRef!: TemplateRef<any>;

  myContent?: any[][];

  get attributes() {
    return this.includeBlockProps
      ? {
          ...getBlockProperties({
            block: this.block,
            context: this.context,
          }),
          ...getBlockActions({
            block: this.block,
            rootState: this.context.rootState,
            rootSetState: this.context.rootSetState,
            localState: this.context.localState,
            context: this.context.context,
          }),
        }
      : {};
  }
  mergedInputs_atkjh2 = {} as any;

  constructor(private vcRef: ViewContainerRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.mergedInputs_atkjh2 = {
      ...this.wrapperProps,
      ...(this.hasAttributesInput(this.Wrapper) ? { attributes: this.attributes } : {})
    };

    this.myContent = [
      this.vcRef.createEmbeddedView(this.wrapperTemplateRef).rootNodes,
    ];
  }

    
  _listenerFns = new Map<string, () => void>();

  private hasAttributesInput(component): boolean {
    return !!reflectComponentType(component)?.inputs.find(input => input.propName === 'attributes');
  }

  private updateAttributes(
    el: HTMLElement,
    attributes: { [key: string]: any }
  ): void {
    Object.keys(attributes).forEach((attr) => {
      if (attr.startsWith("on")) {
        if (this._listenerFns.has(attr)) {
          this._listenerFns.get(attr)!();
        }
        this._listenerFns.set(
          attr,
          this.renderer.listen(
            el,
            attr.replace("on", "").toLowerCase(),
            attributes[attr]
          )
        );
      } else if (attr === 'class' && attributes[attr]) {
        const classes = attributes[attr].split(' ');
        classes.forEach((cls: string) =>
          this.renderer.addClass(el, cls.trim())
        );
      } else {
        this.renderer.setAttribute(el, attr.toLowerCase(), attributes[attr] ?? "");
      }
    });
  }

  ngAfterViewInit() {
    if (!this.hasAttributesInput(this.Wrapper)) {
      const wrapperElement =
        this.wrapperTemplateRef.elementRef.nativeElement?.nextElementSibling;
      if (wrapperElement) {
        this.updateAttributes(wrapperElement, this.attributes);
      }
    }
  }

  ngOnDestroy() {
    for (const fn of this._listenerFns.values()) {
      fn();
    }
  }
ngOnChanges(changes: SimpleChanges) { if (changes["attributes"] && !this.hasAttributesInput(this.Wrapper)) { this.ngAfterViewInit(); }
    if (typeof window !== "undefined") {
      this.mergedInputs_atkjh2 = {
        ...this.wrapperProps,
        ...(this.hasAttributesInput(this.Wrapper) ? { attributes: this.attributes } : {})
      };
    }
  }
}
