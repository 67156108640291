import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

/**
 * Placeholder component to be overridden by specific SDKs.
 * Allows to dynamically import components.
 */

type AwaiterProps = {
  load: () => Promise<any>;
  props?: any;
  attributes?: any;
  fallback?: any;
  children?: any;
};

@Component({
  selector: "awaiter",
  template: `
    <ng-container><ng-content></ng-content></ng-container>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export default class Awaiter {
  @Input() load!: AwaiterProps["load"];
  @Input() props!: AwaiterProps["props"];
  @Input() attributes!: AwaiterProps["attributes"];
  @Input() fallback!: AwaiterProps["fallback"];
}
