import { Injectable } from '@angular/core';
import { BrandTheme } from '../types/brand-theme.interface';
import { defaultTheme } from '../theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private currentTheme: BrandTheme = defaultTheme;

  constructor() {
    this.applyTheme();
  }

  applyTheme() {
    this.setThemeProperties();
    this.updateFavicons();
  }

  private setThemeProperties() {
    const root = document.documentElement;
    
    // Typography
    root.style.setProperty('--theme-font-primary', this.currentTheme.typography.fonts.primary.family);
    root.style.setProperty('--theme-font-secondary', this.currentTheme.typography.fonts.secondary.family);
    
    // Font weights
    root.style.setProperty('--theme-font-weight-light', this.currentTheme.typography.fonts.primary.weights.light.toString());
    root.style.setProperty('--theme-font-weight-regular', this.currentTheme.typography.fonts.primary.weights.regular.toString());
    root.style.setProperty('--theme-font-weight-medium', this.currentTheme.typography.fonts.primary.weights.medium.toString());
    root.style.setProperty('--theme-font-weight-semibold', this.currentTheme.typography.fonts.primary.weights.semibold.toString());
    root.style.setProperty('--theme-font-weight-bold', this.currentTheme.typography.fonts.primary.weights.bold.toString());
    
    // Line heights
    root.style.setProperty('--theme-leading-none', this.currentTheme.typography.lineHeights.none.toString());
    root.style.setProperty('--theme-leading-tight', this.currentTheme.typography.lineHeights.tight.toString());
    root.style.setProperty('--theme-leading-snug', this.currentTheme.typography.lineHeights.snug.toString());
    root.style.setProperty('--theme-leading-normal', this.currentTheme.typography.lineHeights.normal.toString());
    root.style.setProperty('--theme-leading-relaxed', this.currentTheme.typography.lineHeights.relaxed.toString());
    root.style.setProperty('--theme-leading-loose', this.currentTheme.typography.lineHeights.loose.toString());
    
    // Colors
    root.style.setProperty('--theme-color-primary', this.currentTheme.colors.primary);
    root.style.setProperty('--theme-color-secondary', this.currentTheme.colors.secondary);
    root.style.setProperty('--theme-color-background', this.currentTheme.colors.background);
    root.style.setProperty('--theme-color-text-primary', this.currentTheme.colors.text.primary);
    root.style.setProperty('--theme-color-text-secondary', this.currentTheme.colors.text.secondary);
    root.style.setProperty('--theme-color-button-primary', this.currentTheme.colors.button.primary);
    root.style.setProperty('--theme-color-button-secondary', this.currentTheme.colors.button.secondary);
  }

  private updateFavicons() {
    // Update favicon.ico
    let favicon = document.querySelector<HTMLLinkElement>('link[rel="icon"][type="image/x-icon"]');
    if (!favicon) {
      favicon = document.createElement('link');
      favicon.rel = 'icon';
      favicon.type = 'image/x-icon';
      document.head.appendChild(favicon);
    }
    favicon.href = this.currentTheme.favicons.ico;

    // Update apple-touch-icon
    let appleTouchIcon = document.querySelector<HTMLLinkElement>('link[rel="apple-touch-icon"]');
    if (!appleTouchIcon) {
      appleTouchIcon = document.createElement('link');
      appleTouchIcon.setAttribute('rel', 'apple-touch-icon');
      appleTouchIcon.setAttribute('sizes', '180x180');
      document.head.appendChild(appleTouchIcon);
    }
    appleTouchIcon.href = this.currentTheme.favicons.appleTouchIcon;

    // Update favicon-32x32
    let favicon32 = document.querySelector<HTMLLinkElement>('link[rel="icon"][sizes="32x32"]');
    if (!favicon32) {
      favicon32 = document.createElement('link');
      favicon32.setAttribute('rel', 'icon');
      favicon32.setAttribute('type', 'image/png');
      favicon32.setAttribute('sizes', '32x32');
      document.head.appendChild(favicon32);
    }
    favicon32.href = this.currentTheme.favicons.favicon32;

    // Update favicon-16x16
    let favicon16 = document.querySelector<HTMLLinkElement>('link[rel="icon"][sizes="16x16"]');
    if (!favicon16) {
      favicon16 = document.createElement('link');
      favicon16.setAttribute('rel', 'icon');
      favicon16.setAttribute('type', 'image/png');
      favicon16.setAttribute('sizes', '16x16');
      document.head.appendChild(favicon16);
    }
    favicon16.href = this.currentTheme.favicons.favicon16;

    // Update manifest
    let manifest = document.querySelector<HTMLLinkElement>('link[rel="manifest"]');
    if (!manifest) {
      manifest = document.createElement('link');
      manifest.rel = 'manifest';
      document.head.appendChild(manifest);
    }
    manifest.href = this.currentTheme.favicons.manifest;
  }
}