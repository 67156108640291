<div class="main">
    <app-announcement-bar></app-announcement-bar>
    <app-navbar></app-navbar>
    
    <div class="not-found-content">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>The page you're looking for doesn't exist or has been moved.</p>
      <a routerLink="/" class="home-button">Return to Home</a>
    </div>
  
    <app-footer></app-footer>
  </div>