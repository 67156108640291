import { Component } from '@angular/core';
import { CommonModule} from '@angular/common';
import { Router } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { fetchOneEntry, type BuilderContent } from '@builder.io/sdk-angular';
import { Content } from '@builder.io/sdk-angular';
import { environment } from '../../../environments/environment';
import { AnnouncementBarComponent } from '../../components/announcement-bar/announcement-bar.component';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { forumbrandsComponents } from '../../builder-registry';

@Component({
  selector: 'app-landing-page',
  imports: [
    Content, 
    CommonModule, 
    MatProgressSpinnerModule,
    AnnouncementBarComponent, 
    NavbarComponent, 
    FooterComponent
  ],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
  standalone: true
})
export class LandingPageComponent {
  apiKey = environment.builderApiKey;
  model = 'page'
  content: BuilderContent | null = null;
  customComponents = forumbrandsComponents;
  isLoading = true;

  constructor(private router: Router) {}

  async ngOnInit() {
    try {
      const urlPath = window.location.pathname || '';
      const content = await fetchOneEntry({
        apiKey: this.apiKey,
        model: this.model,
        userAttributes: {
          urlPath,
        }
      });

      if (!content) {
        console.warn('No content found for the current URL.');
        await this.router.navigate(['/404']);
        return;
      }

      this.content = content;
    } catch (error) {
      console.error('Error fetching content:', error);
      await this.router.navigate(['/404']);
    } finally {
      this.isLoading = false;
    }
  }
}
