import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BrandConfigService } from '../services/brand-config.service';
import { ThemeService } from '../services/theme.service';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title: string;

  constructor(private brandConfig: BrandConfigService, private themeService: ThemeService) {
    this.title = this.brandConfig.currentConfig.siteTitle;
  }

  ngOnInit() {
    this.themeService.applyTheme();
  }
}

