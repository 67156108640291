import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandConfigService } from '../../../services/brand-config.service';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  logo: string;
  brandName: string;
  navigationLinks: { text: string; url: string; }[];
  isMobileMenuOpen: boolean = false;
  
  constructor(private brandConfig: BrandConfigService) {
    const config = this.brandConfig.currentConfig;
    this.logo = config.brandLogoUrl;
    this.brandName = config.brandName;
    
    this.navigationLinks = [
      { text: 'LOGIN', url: config.loginShopifyUrl },
      { text: 'REQUEST ACCESS', url: '/request-access' },
    ];
  }

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }
}
