  <form (ngSubmit)="handleSubmit($event)">
    <div class="form-group">
      <label for="title">Title</label>
      <input
        type="text"
        id="title"
        [(ngModel)]="title"
        name="title"
        required
        [class.error]="titleError"
        placeholder="Mr./Mrs./Ms./Dr."
      />
      <p class="error-message" *ngIf="titleError">{{ titleError }}</p>
    </div>

    <div class="form-group">
      <label for="firstName">First Name</label>
      <input
        type="text"
        id="firstName"
        [(ngModel)]="firstName"
        name="firstName"
        required
        [class.error]="firstNameError"
        placeholder="Enter your first name"
      />
      <p class="error-message" *ngIf="firstNameError">{{ firstNameError }}</p>
    </div>

    <div class="form-group">
      <label for="lastName">Last Name</label>
      <input
        type="text"
        id="lastName"
        [(ngModel)]="lastName"
        name="lastName"
        required
        [class.error]="lastNameError"
        placeholder="Enter your last name"
      />
      <p class="error-message" *ngIf="lastNameError">{{ lastNameError }}</p>
    </div>

    <div class="form-group">
      <label for="email">Email Address</label>
      <input
        type="email"
        id="email"
        [(ngModel)]="email"
        name="email"
        required
        [class.error]="emailError"
        placeholder="example@domain.com"
      />
      <p class="error-message" *ngIf="emailError">{{ emailError }}</p>
    </div>

    <div class="form-group">
      <label for="companyName">Company Name</label>
      <input
        type="text"
        id="companyName"
        [(ngModel)]="companyName"
        name="companyName"
        required
        [class.error]="companyNameError"
        placeholder="Enter company name"
      />
      <p class="error-message" *ngIf="companyNameError">{{ companyNameError }}</p>
    </div>

    <div class="form-group">
      <label for="companyType">Type of Company</label>
      <select
        id="companyType"
        [(ngModel)]="companyType"
        name="companyType"
        required
        [class.error]="companyTypeError"
      >
        <option value="">Select company type</option>
        <option *ngFor="let type of companyTypes" [value]="type">
          {{ type }}
        </option>
      </select>
      <p class="error-message" *ngIf="companyTypeError">{{ companyTypeError }}</p>
    </div>

    <!-- Add new field for other company type -->
    <div class="form-group" *ngIf="companyType === 'Other'">
      <label for="otherCompanyType">Please specify your company type</label>
      <input
        type="text"
        id="otherCompanyType"
        [(ngModel)]="otherCompanyType"
        name="otherCompanyType"
        required
        [class.error]="errors['otherCompanyType']"
        placeholder="Enter your company type"
      />
      <p class="error-message" *ngIf="errors['otherCompanyType']">{{ errors['otherCompanyType'] }}</p>
    </div>

    <div class="form-group">
      <label for="state">Location</label>
      <select
        id="state"
        [(ngModel)]="state"
        name="state"
        required
        [class.error]="stateError"
      >
        <option value="">Select your state</option>
        <option *ngFor="let state of stateOptions" [value]="state">
          {{ state }}
        </option>
      </select>
      <p class="error-message" *ngIf="stateError">{{ stateError }}</p>
      <p class="helper-text">
        Note: We currently only support businesses located in the continental United States.
      </p>
    </div>

    <div class="form-group">
      <label for="productUsage">How do you plan to use LOLA products?</label>
      <select
        id="productUsage"
        [(ngModel)]="productUsage"
        name="productUsage"
        required
        [class.error]="productUsageError"
        (ngModelChange)="onProductUsageChange()"
      >
        <option value="">Select usage type</option>
        <option *ngFor="let type of productUsageTypes" [value]="type">
          {{ type }}
        </option>
      </select>
      <p class="error-message" *ngIf="productUsageError">{{ productUsageError }}</p>
    </div>

    <div class="reseller-message" *ngIf="isReseller">
      <p>
        If you are looking to resell LOLA products, please visit our 
        <a href="https://www.faire.com/brand/b_7x6lbqm2jx" target="_blank">
          LOLA Faire Store
        </a> 
        to access Retail Ready packaging at wholesale prices!
      </p>
    </div>

    <div class="form-group">
      <label for="companyWebsite">Company Website</label>
      <input
        type="url"
        id="companyWebsite"
        [(ngModel)]="companyWebsite"
        name="companyWebsite"
        placeholder="https://example.com"
        required
        [class.error]="companyWebsiteError"
      />
      <p class="error-message" *ngIf="companyWebsiteError">{{ companyWebsiteError }}</p>
    </div>

    <div class="form-group">
      <label for="phoneNumber">Phone Number</label>
      <input
        type="tel"
        id="phoneNumber"
        [(ngModel)]="phoneNumber"
        name="phoneNumber"
        required
        placeholder="+1 (555) 555-5555"
        [class.error]="phoneNumberError"
      />
      <p class="error-message" *ngIf="phoneNumberError">{{ phoneNumberError }}</p>
    </div>

    <p class="required-fields-note">Indicates required fields</p>

    <!-- Add a wraper to center the button and add spacing -->
    <div class="button-wrapper">
      <app-button [loading]="isLoading" type="submit">Submit Request</app-button>
    </div>

    <!-- Add this where you want to display the error message -->
    <div *ngIf="submissionError" class="error-message">
      {{ submissionError }}
    </div>
  </form>