// https://www.builder.io/c/docs/using-your-api-key
type Environment = {
  production: boolean;
  builderApiKey: string;
  shopify?: {
    endpoint: string;
    accessToken: string;
  };
};

export const environment: Environment = {
  production: true,
  builderApiKey: "f4cd348a179d44fd8814530082e4fb29",
  shopify: {
    endpoint: 'https://lola-b2b-store.myshopify.com/api/2024-01/graphql.json',
    accessToken: '8ffdfd38823e97b38e563fe67f71f602'
  }
};
