import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";

import ContentVariants from "../../components/content-variants/index";
import type { BuilderContent } from "../../types/builder-content";
import { filterAttrs } from "../helpers";
import DynamicDiv from "../../components/dynamic-div";
import { getClassPropName } from "../../functions/get-class-prop-name";
import type { Nullable } from "../../types/typescript";
import { setAttrs } from "../helpers";
import { fetchSymbolContent } from "./symbol.helpers";
import type { SymbolProps } from "./symbol.types";

@Component({
  selector: "builder-symbol",
  template: `
    <div [class]="className" #elRef0>
      <content-variants
        [nonce]="builderContext.nonce"
        [isNestedRender]="true"
        [apiVersion]="builderContext.apiVersion"
        [apiKey]="builderContext.apiKey!"
        [context]="node_0_ContentVariants"
        [customComponents]="node_1_ContentVariants"
        [data]="node_2_ContentVariants"
        [canTrack]="builderContext.canTrack"
        [model]="symbol?.model"
        [content]="contentToUse"
        [linkComponent]="builderLinkComponent"
        [blocksWrapper]="blocksWrapper"
        [contentWrapper]="contentWrapper"
      ></content-variants>
    </div>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, ContentVariants],
})
export default class BuilderSymbol {
  @Input() dataOnly!: SymbolProps["dataOnly"];
  @Input() inheritState!: SymbolProps["inheritState"];
  @Input() renderToLiquid!: SymbolProps["renderToLiquid"];
  @Input() symbol!: SymbolProps["symbol"];
  @Input() builderContext!: SymbolProps["builderContext"];
  @Input() builderBlock!: SymbolProps["builderBlock"];
  @Input() builderComponents!: SymbolProps["builderComponents"];
  @Input() attributes!: SymbolProps["attributes"];
  @Input() dynamic!: SymbolProps["dynamic"];
  @Input() builderLinkComponent!: SymbolProps["builderLinkComponent"];

  @ViewChild("elRef0") elRef0!: ElementRef;

  _listenerFns = new Map<string, () => void>();

  get blocksWrapper() {
    return DynamicDiv;
  }
  get contentWrapper() {
    return DynamicDiv;
  }
  get className() {
    return [
      ...[this.attributes[getClassPropName()]],
      "builder-symbol",
      this.symbol?.inline ? "builder-inline-symbol" : undefined,
      this.symbol?.dynamic || this.dynamic
        ? "builder-dynamic-symbol"
        : undefined,
    ]
      .filter(Boolean)
      .join(" ");
  }
  contentToUse = null;
  setContent() {
    if (this.contentToUse) return;
    fetchSymbolContent({
      symbol: this.symbol,
      builderContextValue: this.builderContext,
    }).then((newContent) => {
      if (newContent) {
        this.contentToUse = newContent;
      }
    });
  }
  node_0_ContentVariants = null;
  node_1_ContentVariants = null;
  node_2_ContentVariants = null;
  elRef0_state_0 = null;
  setAttributes(el: HTMLElement, value: any, changes?: any) {
    if (!el) {
      return;
    }
    const target = typeof changes === "undefined" ? value : changes;
    Object.keys(target).forEach((key) => {
      if (key.startsWith("on")) {
        if (this._listenerFns.has(key)) {
          this._listenerFns.get(key)!();
        }
        this._listenerFns.set(
          key,
          this.renderer.listen(
            el,
            key.replace("on", "").toLowerCase(),
            target[key]
          )
        );
      } else {
        this.renderer.setAttribute(el, key.toLowerCase(), target[key] ?? "");
      }
    });
  }
  elRef0_state_1 = null;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.contentToUse = this.symbol?.content;
    this.node_0_ContentVariants = {
      ...this.builderContext.context,
      symbolId: this.builderBlock?.id,
    };
    this.node_1_ContentVariants = Object.values(this.builderComponents);
    this.node_2_ContentVariants = {
      ...this.symbol?.data,
      ...this.builderContext.localState,
      ...this.contentToUse?.data?.state,
    };
    this.elRef0_state_0 = {};
    this.elRef0_state_1 = {};
  }

  ngAfterViewInit() {
    this.setAttributes(this.elRef0?.nativeElement, this.elRef0_state_0);
    this.setAttributes(this.elRef0?.nativeElement, this.attributes);
    this.setAttributes(this.elRef0?.nativeElement, this.elRef0_state_1);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof window !== "undefined") {
      if (changes.symbol) { this.setContent(); }
      this.node_0_ContentVariants = {
        ...this.builderContext.context,
        symbolId: this.builderBlock?.id,
      };
      this.node_1_ContentVariants = Object.values(this.builderComponents);
      this.node_2_ContentVariants = {
        ...this.symbol?.data,
        ...this.builderContext.localState,
        ...this.contentToUse?.data?.state,
      };
      this.elRef0_state_0 = {};
      this.setAttributes(
        this.elRef0?.nativeElement,
        this.elRef0_state_0,
        changes["elRef0_state_0"]?.currentValue
      );
      this.setAttributes(
        this.elRef0?.nativeElement,
        this.attributes,
        changes["attributes"]?.currentValue
      );
      this.elRef0_state_1 = {};
      this.setAttributes(
        this.elRef0?.nativeElement,
        this.elRef0_state_1,
        changes["elRef0_state_1"]?.currentValue
      );
    }
  }

  ngOnDestroy() {
    for (const fn of this._listenerFns.values()) {
      fn();
    }
  }
}
