import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  ViewContainerRef,
  TemplateRef,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";

export type BlocksWrapperProps = {
  blocks: BuilderBlock[] | undefined;
  parent: string | undefined;
  path: string | undefined;
  styleProp: Record<string, any> | undefined;
  /**
   * The element that wraps each list of blocks. Defaults to a `div` element ('ScrollView' in React Native).
   */
  BlocksWrapper: any;
  /**
   * Additonal props to pass to `blocksWrapper`. Defaults to `{}`.
   */
  BlocksWrapperProps: any;
  children?: any;
  classNameProp?: string;
};

import { isEditing } from "../../functions/is-editing";
import type { BuilderBlock } from "../../types/builder-block";

@Component({
  selector: "blocks-wrapper",
  template: `
    <ng-template #blockswrapperTemplate><ng-content></ng-content></ng-template>
    <ng-container
      *ngComponentOutlet="
              BlocksWrapper;
              inputs: mergedInputs_2eim3t;
              content: myContent;
              "
    ></ng-container>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
      .props-blocks-wrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export default class BlocksWrapper {
  @Input() blocks!: BlocksWrapperProps["blocks"];
  @Input() classNameProp!: BlocksWrapperProps["classNameProp"];
  @Input() path!: BlocksWrapperProps["path"];
  @Input() parent!: BlocksWrapperProps["parent"];
  @Input() styleProp!: BlocksWrapperProps["styleProp"];
  @Input() BlocksWrapperProps!: BlocksWrapperProps["BlocksWrapperProps"];
  @Input() BlocksWrapper!: BlocksWrapperProps["BlocksWrapper"];

  @ViewChild("blocksWrapperRef") blocksWrapperRef!: ElementRef;

  @ViewChild("blockswrapperTemplate", { static: true })
  blockswrapperTemplateRef!: TemplateRef<any>;

  myContent?: any[][];

  get className() {
    return [
      "builder-blocks",
      !this.blocks?.length ? "no-blocks" : "",
      this.classNameProp,
    ]
      .filter(Boolean)
      .join(" ");
  }
  get dataPath() {
    if (!this.path) {
      return undefined;
    }
    const pathPrefix = "component.options.";
    return this.path.startsWith(pathPrefix)
      ? this.path
      : `${pathPrefix}${this.path || ""}`;
  }
  onClick() {
    if (isEditing() && !this.blocks?.length) {
      window.parent?.postMessage(
        {
          type: "builder.clickEmptyBlocks",
          data: {
            parentElementId: this.parent,
            dataPath: this.dataPath,
          },
        },
        "*"
      );
    }
  }
  onMouseEnter() {
    if (isEditing() && !this.blocks?.length) {
      window.parent?.postMessage(
        {
          type: "builder.hoverEmptyBlocks",
          data: {
            parentElementId: this.parent,
            dataPath: this.dataPath,
          },
        },
        "*"
      );
    }
  }
  mergedInputs_2eim3t = {} as any;

  constructor(private vcRef: ViewContainerRef) {}

  ngAfterContentInit() {
    this.mergedInputs_2eim3t = {
      ref: this.blocksWrapperRef,
      class: this.className + " props-blocks-wrapper",
      "builder-path": this.dataPath,
      "builder-parent-id": this.parent,
      style: this.styleProp,
      onClick: this.onClick.bind(this),
      onMouseEnter: this.onMouseEnter.bind(this),
      onKeyPress: this.onClick.bind(this),
      ...this.BlocksWrapperProps,
    };

    if (typeof window !== "undefined") {
    }

    this.myContent = [
      this.vcRef.createEmbeddedView(this.blockswrapperTemplateRef).rootNodes,
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof window !== "undefined") {
      this.mergedInputs_2eim3t = {
        ref: this.blocksWrapperRef,
        class: this.className + " props-blocks-wrapper",
        "builder-path": this.dataPath,
        "builder-parent-id": this.parent,
        style: this.styleProp,
        onClick: this.onClick.bind(this),
        onMouseEnter: this.onMouseEnter.bind(this),
        onKeyPress: this.onClick.bind(this),
        ...this.BlocksWrapperProps,
      };
    }
  }
}
