import type { RegisteredComponent } from "@builder.io/sdk-angular";
import { HeroComponent } from "./components/hero/hero.component";
import { LoginComponent } from "./components/login/login.component";
import { RegistrationComponent } from "./components/forms/registration/registration.component";
import { ShopifyCollectionComponent } from './components/shopify-collection/shopify-collection.component';
import { FooterComponent } from './components/footer/footer.component';
import { WholesaleOfferComponent } from "./wholesale-offer/wholesale-offer.component";

export const forumbrandsComponents: RegisteredComponent[] = [
  {
    component: HeroComponent,
    name: "Hero",
    noWrap: true,
    inputs: [
      {
        name: 'backgroundImageUrl',
        type: 'file',
        allowedFileTypes: ['image'],
        helperText: 'Background image for the hero section'
      },
      {
        name: 'title',
        type: 'string',
        defaultValue: 'Welcome to Our Platform',
        helperText: 'Main heading text for the hero section'
      },
      {
        name: 'description',
        type: 'longText',
        defaultValue: 'Join our network of successful partners and unlock exclusive wholesale pricing and comprehensive business support.',
        helperText: 'Description text below the heading'
      },
      {
        name: 'buttonText',
        type: 'string',
        defaultValue: 'GET STARTED',
        helperText: 'Text to display on the CTA button'
      },
      {
        name: 'buttonUrl',
        type: 'string',
        defaultValue: '/',
        helperText: 'URL for the CTA button (defaults to "/")'
      }
    ],
  },
  {
    component: LoginComponent,
    name: "Login",
    noWrap: true,
    inputs: [
      {
        name: 'loginUrl',
        type: 'string',
        defaultValue: '/',
        helperText: 'URL for the login button (defaults to "/")'
      },
      {
        name: 'requestAccessUrl',
        type: 'string',
        defaultValue: '/request-access',
        helperText: 'URL for the request access button (defaults to "/request-access")'
      }
    ]
  },
  {
    component: RegistrationComponent,
    name: "Registration",
  },
  {
    component: ShopifyCollectionComponent,
    name: "Shopify Collection Component",
    noWrap: true,
    shouldReceiveBuilderProps: {
      builderContext: true
    }
  },
  {
    component: WholesaleOfferComponent,
    name: "Wholesale Offer",
    noWrap: true,
    inputs: [
      {
        name: 'sectionImage',
        type: 'file',
        allowedFileTypes: ['image'],
        helperText: 'Image for the wholesale section'
      },
      {
        name: 'sectionTitle',
        type: 'string',
        defaultValue: 'Wholesale Partnership',
        helperText: 'Main heading for the wholesale section'
      },
      {
        name: 'sectionDescription',
        type: 'longText',
        defaultValue: 'Join our network of trusted distributors and gain access to exclusive wholesale pricing.',
        helperText: 'Description text for the wholesale section'
      },
      {
        name: 'ctaButtonText',
        type: 'string',
        defaultValue: 'APPLY NOW',
        helperText: 'Text to display on the CTA button'
      },
      {
        name: 'productHighlights',
        type: 'array',
        subFields: [
          {
            name: 'highlight',
            type: 'string'
          }
        ],
        defaultValue: [
          '100% Organic Cotton Tampons',
          'Ultra-Thin Pads with Wings',
          'Natural Latex Condoms',
          'Award-Winning Personal Lubricant'
        ],
        helperText: 'List of product highlights to display'
      },
      {
        name: 'buttonUrl',
        type: 'string',
        defaultValue: '/request-access',
        helperText: 'URL for the CTA button'
      },
      {
        name: 'loginText',
        type: 'string',
        defaultValue: 'Already have an account?',
        helperText: 'Text before the login link'
      },
      {
        name: 'loginLinkText',
        type: 'string',
        defaultValue: 'Log in',
        helperText: 'Text for the login link itself'
      },
      {
        name: 'loginUrl',
        type: 'string',
        defaultValue: '/',
        helperText: 'URL for the login link'
      }
    ]
  },
  {
    component: FooterComponent,
    name: "Footer",
    noWrap: true,
    inputs: [
      {
        name: 'contactInfo',
        type: 'object',
        defaultValue: {
          hours: 'Business Hours',
          email: 'contact@example.com',
          phone: {
            us: '+1-XXX-XXX-XXXX',
            canada: '+1-XXX-XXX-XXXX'
          }
        },
        helperText: 'Contact information displayed in the footer'
      },
      {
        name: 'footerSections',
        type: 'array',
        defaultValue: [
          {
            title: 'INFORMATION',
            links: [
              { text: 'Become a Partner', url: '/request-access' }
            ]
          }
        ],
        helperText: 'Navigation sections displayed in the footer'
      },
      {
        name: 'socialLinks',
        type: 'object',
        defaultValue: {
          facebook: 'https://www.facebook.com/',
          pinterest: 'https://www.pinterest.com/',
          instagram: 'https://www.instagram.com/',
          youtube: 'https://www.youtube.com/'
        },
        helperText: 'Social media links displayed in the footer'
      },
      {
        name: 'copyright',
        type: 'object',
        defaultValue: {
          brandName: 'Company Name',
          tagline: 'Your Brand Tagline'
        },
        helperText: 'Copyright information displayed in the footer'
      }
    ]
  }
];
