import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";

import { filterAttrs } from "../helpers";
import type { SectionProps } from "./section.types";
import { setAttrs } from "../helpers";

@Component({
  selector: "section-component",
  template: `
    <section [ngStyle]="node_0_section" #elRef0>
      <ng-content></ng-content>
    </section>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export default class SectionComponent {
  @Input() maxWidth!: SectionProps["maxWidth"];
  @Input() attributes!: SectionProps["attributes"];

  @ViewChild("elRef0") elRef0!: ElementRef;

  _listenerFns = new Map<string, () => void>();

  node_0_section = null;
  elRef0_state_0 = null;
  setAttributes(el: HTMLElement, value: any, changes?: any) {
    if (!el) {
      return;
    }
    const target = typeof changes === "undefined" ? value : changes;
    Object.keys(target).forEach((key) => {
      if (key.startsWith("on")) {
        if (this._listenerFns.has(key)) {
          this._listenerFns.get(key)!();
        }
        this._listenerFns.set(
          key,
          this.renderer.listen(
            el,
            key.replace("on", "").toLowerCase(),
            target[key]
          )
        );
      } else {
        this.renderer.setAttribute(el, key.toLowerCase(), target[key] ?? "");
      }
    });
  }

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.node_0_section = {
      width: "100%",
      alignSelf: "stretch",
      flexGrow: 1,
      boxSizing: "border-box",
      maxWidth: this.maxWidth || 1200,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      marginLeft: "auto",
      marginRight: "auto",
    };
    this.elRef0_state_0 = {};
  }

  ngAfterViewInit() {
    this.setAttributes(this.elRef0?.nativeElement, this.elRef0_state_0);
    this.setAttributes(this.elRef0?.nativeElement, this.attributes);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof window !== "undefined") {
      this.node_0_section = {
        width: "100%",
        alignSelf: "stretch",
        flexGrow: 1,
        boxSizing: "border-box",
        maxWidth: this.maxWidth || 1200,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        marginLeft: "auto",
        marginRight: "auto",
      };
      this.elRef0_state_0 = {};
      this.setAttributes(
        this.elRef0?.nativeElement,
        this.elRef0_state_0,
        changes["elRef0_state_0"]?.currentValue
      );
      this.setAttributes(
        this.elRef0?.nativeElement,
        this.attributes,
        changes["attributes"]?.currentValue
      );
    }
  }

  ngOnDestroy() {
    for (const fn of this._listenerFns.values()) {
      fn();
    }
  }
}
