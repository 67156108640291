import type { ComponentInfo } from '../../types/components';
export const componentInfo: ComponentInfo = {
  name: 'Custom Code',
  static: true,
  requiredPermissions: ['editCode'],
  inputs: [{
    name: 'code',
    type: 'html',
    required: true,
    defaultValue: '<p>Hello there, I am custom HTML code!</p>',
    code: true
  }, {
    name: 'replaceNodes',
    type: 'boolean',
    helperText: 'Preserve server rendered dom nodes',
    advanced: true
  }, {
    name: 'scriptsClientOnly',
    type: 'boolean',
    defaultValue: false,
    helperText: 'Only print and run scripts on the client. Important when scripts influence DOM that could be replaced when client loads',
    advanced: true
  }]
}