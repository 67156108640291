import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, style, animate, transition } from '@angular/animations';
import { environment } from '../../../environments/environment';
import { getBuilderModels } from '../../constants/builder.constants';
import { fetchEntries } from '@builder.io/sdk-angular';
import { BrandConfigService } from '../../../services/brand-config.service';

interface Announcement {
  text: string;
  url: string;
}

@Component({
  selector: 'app-announcement-bar',
  templateUrl: './announcement-bar.component.html',
  styleUrls: ['./announcement-bar.component.scss'],
  standalone: true,
  imports: [CommonModule],
  animations: [
    trigger('slideAnimation', [
      transition('* => *', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-out', style({ transform: 'translateX(0)' }))
      ])
    ])
  ]
})
export class AnnouncementBarComponent implements OnInit, OnDestroy {
  currentIndex: number = 0;
  private autoRotateInterval: any;
  announcements: Announcement[] = [];
  hasError: boolean = false;
  apiKey = environment.builderApiKey;

  constructor(private brandConfig: BrandConfigService) {}

  async ngOnInit() {
    try {
      await this.fetchAnnouncements();
      if (this.announcements.length > 0) {
        this.startAutoRotate();
      }
    } catch (error) {
      console.error('Error initializing announcement bar:', error);
      this.hasError = true;
    }
  }

  ngOnDestroy() {
    this.stopAutoRotate();
  }

  private async fetchAnnouncements() {
    try {
      const content = await fetchEntries({
        model: getBuilderModels(this.brandConfig).ANNOUNCEMENTS.model,
        apiKey: this.apiKey,
        userAttributes: {
          urlPath: window.location.pathname
        }
      });

      if (content && content.length > 0) {
        const b2bAnnouncementsContent = content.find(
          (item: any) => item.name === getBuilderModels(this.brandConfig).ANNOUNCEMENTS.name
        );
        
        if (b2bAnnouncementsContent?.data?.[getBuilderModels(this.brandConfig).ANNOUNCEMENTS.dataKey]) {
          this.announcements = this.transformAnnouncements(
            b2bAnnouncementsContent.data[getBuilderModels(this.brandConfig).ANNOUNCEMENTS.dataKey]
          );
        } else {
          console.warn('No announcements found in the B2B Announcements content');
          this.hasError = true;
        }
      } else {
        this.hasError = true;
      }
    } catch (error) {
      console.error('Error fetching announcements:', error);
      this.hasError = true;
      throw error;
    }
  }

  private transformAnnouncements(rawAnnouncements: any[]): Announcement[] {
    return rawAnnouncements.map(announcement => {
      const text = typeof announcement.text === 'string' ? announcement.text : '';
      const url = typeof announcement.url === 'string' ? announcement.url : '#';

      return {
        text,
        url
      };
    }).filter(announcement => announcement.text);
  }

  private startAutoRotate() {
    this.autoRotateInterval = setInterval(() => {
      this.nextAnnouncement();
    }, 5000);
  }

  private stopAutoRotate() {
    if (this.autoRotateInterval) {
      clearInterval(this.autoRotateInterval);
    }
  }

  nextAnnouncement() {
    this.currentIndex = (this.currentIndex + 1) % this.announcements.length;
  }

  onMouseEnter() {
    this.stopAutoRotate();
  }

  onMouseLeave() {
    this.startAutoRotate();
  }
}