<div id="shopify-featured-product-collection">
  <div class="product-container">
    <!-- Swiper Wrapper -->
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let product of (products.length > 0 ? products : placeholderProducts)">
          <div class="product">
            <div class="product-image-container">
              <img 
                [alt]="product.title" 
                class="product-image" 
                [ngSrc]="getProductImage(product)" 
                width="280"
                height="280"
                priority
              />
            </div>
            <h3 class="product-title">{{ product.title | uppercase }}</h3>
            <div class="product-features">
              <ng-container *ngIf="product.metafields && product.metafields.length > 0 && product.metafields[0]?.value">
                <p class="product-feature" *ngFor="let feature of getFeatures(product.metafields[0].value)">
                  {{ feature }}
                </p>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</div>
