import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";

/**
 * This import is used by the Svelte SDK. Do not remove.
 */

export interface FormSelectProps {
  options?: {
    name?: string;
    value: string;
  }[];
  attributes?: any;
  name?: string;
  value?: string;
  defaultValue?: string;
  required?: boolean;
}

import { isEditing } from "../../../functions/is-editing";
import { filterAttrs } from "../../helpers";
import { setAttrs } from "../../helpers";

@Component({
  selector: "select-component",
  template: `
    <select
      [attr.value]="value"
      [attr.defaultValue]="defaultValue"
      [attr.name]="name"
      [attr.required]="required"
      #elRef0
    >
      <ng-container
        *ngFor="let option of options; let index = index; trackBy: trackByOption0"
      >
        <option [attr.value]="option.value">
          {{option.name || option.value}}
        </option>
      </ng-container>
    </select>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export default class SelectComponent {
  @Input() defaultValue!: FormSelectProps["defaultValue"];
  @Input() attributes!: FormSelectProps["attributes"];
  @Input() value!: FormSelectProps["value"];
  @Input() name!: FormSelectProps["name"];
  @Input() required!: FormSelectProps["required"];
  @Input() options!: FormSelectProps["options"];

  @ViewChild("elRef0") elRef0!: ElementRef;

  _listenerFns = new Map<string, () => void>();

  node_0_select = null;
  elRef0_state_0 = null;
  setAttributes(el: HTMLElement, value: any, changes?: any) {
    if (!el) {
      return;
    }
    const target = typeof changes === "undefined" ? value : changes;
    Object.keys(target).forEach((key) => {
      if (key.startsWith("on")) {
        if (this._listenerFns.has(key)) {
          this._listenerFns.get(key)!();
        }
        this._listenerFns.set(
          key,
          this.renderer.listen(
            el,
            key.replace("on", "").toLowerCase(),
            target[key]
          )
        );
      } else {
        this.renderer.setAttribute(el, key.toLowerCase(), target[key] ?? "");
      }
    });
  }
  trackByOption0(index, option) {
    return `${option.name}-${index}`;
  }

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.node_0_select =
      isEditing() && this.defaultValue ? this.defaultValue : "default-key";
    this.elRef0_state_0 = {};
  }

  ngAfterViewInit() {
    this.setAttributes(this.elRef0?.nativeElement, this.elRef0_state_0);
    this.setAttributes(this.elRef0?.nativeElement, this.attributes);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof window !== "undefined") {
      this.node_0_select =
        isEditing() && this.defaultValue ? this.defaultValue : "default-key";
      this.elRef0_state_0 = {};
      this.setAttributes(
        this.elRef0?.nativeElement,
        this.elRef0_state_0,
        changes["elRef0_state_0"]?.currentValue
      );
      this.setAttributes(
        this.elRef0?.nativeElement,
        this.attributes,
        changes["attributes"]?.currentValue
      );
    }
  }

  ngOnDestroy() {
    for (const fn of this._listenerFns.values()) {
      fn();
    }
  }
}
