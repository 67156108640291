import { Injectable } from '@angular/core';
import { BrandConfig } from '../types/brand-config.interface';
import { defaultConfig } from '../config';

@Injectable({
  providedIn: 'root'
})
export class BrandConfigService {
  private _currentConfig: BrandConfig = defaultConfig;

  get currentConfig(): BrandConfig {
    return this._currentConfig;
  }
} 