<div class="wholesale-container">
    <div class="wholesale-box">
      
      <!-- Left Side: Image -->
      <div class="wholesale-image">
        <img src="/images/cta.jpg" alt="Wholesale Offer">
      </div>
  
      <!-- Right Side: Offer Details -->
      <div class="wholesale-text">
        <h2>{{ sectionTitle }}</h2>
        <p>{{ sectionDescription }}</p>
  
        <h3>{{ highlightsTitle }}</h3>
        <ul>
          <li *ngFor="let product of productHighlights">✔ {{ product.highlight }}</li>
        </ul>
  
        <app-button (click)="onApplyClick()">{{ ctaButtonText }}</app-button>
        <p class="wholesale-login">
          {{ loginText }} <a (click)="onLoginClick()">{{ loginLinkText }}</a>
        </p>
      </div>
  
    </div>
  </div>
  