<div class="main">
  <app-announcement-bar></app-announcement-bar>
  <app-navbar></app-navbar>
  
  <ng-container *ngIf="!isLoading; else loading">
    <ng-container *ngIf="content">
      <builder-content [model]="model" [content]="content" [apiKey]="apiKey" [customComponents]="customComponents"></builder-content>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <div class="spinner-container flex-grow">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>

  <app-footer></app-footer>
</div>
