import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ButtonComponent } from '../components/shared/button/button.component';
import { BrandConfigService } from '../../services/brand-config.service';
import { HighlightItem } from '../../types/components';
@Component({
  selector: 'app-wholesale-offer',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './wholesale-offer.component.html',
  styleUrl: './wholesale-offer.component.scss',
})
export class WholesaleOfferComponent {
  private _sectionImage: any;
  private _sectionTitle: string = '';
  private _sectionDescription: string = '';
  private _ctaButtonText: string = '';
  private _productHighlights: HighlightItem[] = [];
  private _buttonUrl: string = '';
  private _loginText: string = '';
  private _loginUrl: string = '';
  private _loginLinkText: string = '';
  private _highlightsTitle: string = '';

  @Input()
  get sectionImage(): any {
    return this._sectionImage || this.brandConfig.currentConfig.wholesaleImage;
  }
  set sectionImage(value: any) {
    this._sectionImage = value;
  }

  @Input()
  get sectionTitle(): string {
    return this._sectionTitle || this.brandConfig.currentConfig.wholesaleTitle;
  }
  set sectionTitle(value: string) {
    this._sectionTitle = value;
  }

  @Input()
  get sectionDescription(): string {
    return this._sectionDescription || this.brandConfig.currentConfig.wholesaleDescription;
  }
  set sectionDescription(value: string) {
    this._sectionDescription = value;
  }

  @Input()
  get ctaButtonText(): string {
    return this._ctaButtonText || this.brandConfig.currentConfig.wholesaleButtonText;
  }
  set ctaButtonText(value: string) {
    this._ctaButtonText = value;
  }

  @Input()
  get productHighlights(): HighlightItem[] {
    return this._productHighlights.length > 0 
      ? this._productHighlights 
      : this.brandConfig.currentConfig.wholesaleHighlights;
  }
  set productHighlights(value: HighlightItem[]) {
    this._productHighlights = value;
  }

  @Input()
  get buttonUrl(): string {
    return this._buttonUrl || this.brandConfig.currentConfig.wholesaleButtonUrl;
  }
  set buttonUrl(value: string) {
    this._buttonUrl = value;
  }

  @Input()
  get loginText(): string {
    return this._loginText || 'Already have an account?';
  }
  set loginText(value: string) {
    this._loginText = value;
  }

  @Input()
  get loginUrl(): string {
    return this._loginUrl || this.brandConfig.currentConfig.loginShopifyUrl;
  }
  set loginUrl(value: string) {
    this._loginUrl = value;
  }

  @Input()
  get loginLinkText(): string {
    return this._loginLinkText || 'Log in';
  }
  set loginLinkText(value: string) {
    this._loginLinkText = value;
  }

  @Input()
  get highlightsTitle(): string {
    return this._highlightsTitle || this.brandConfig.currentConfig.wholesaleHighlightsTitle;
  }
  set highlightsTitle(value: string) {
    this._highlightsTitle = value;
  }

  constructor(
    private router: Router,
    private brandConfig: BrandConfigService
  ) {}

  onApplyClick(): void {
    this.router.navigate([this.buttonUrl]);
  }

  onLoginClick(): void {
    window.location.href = this.loginUrl;
  }
}
