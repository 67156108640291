import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";

/**
 * This import is used by the Svelte SDK. Do not remove.
 */

export interface ImgProps {
  attributes?: any;
  imgSrc?: string; // TODO(misko): I think this is unused
  image?: string;
  altText?: string;
  backgroundSize?: "cover" | "contain";
  backgroundPosition?:
    | "center"
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "top left"
    | "top right"
    | "bottom left"
    | "bottom right";
}

import { isEditing } from "../../functions/is-editing";
import { filterAttrs } from "../helpers";
import { setAttrs } from "../helpers";

@Component({
  selector: "img-component",
  template: `
    <img
      [ngStyle]="node_0_img"
      [attr.alt]="altText"
      [attr.src]="imgSrc || image"
      #elRef0
    />
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export default class ImgComponent {
  @Input() backgroundSize!: ImgProps["backgroundSize"];
  @Input() backgroundPosition!: ImgProps["backgroundPosition"];
  @Input() imgSrc!: ImgProps["imgSrc"];
  @Input() altText!: ImgProps["altText"];
  @Input() image!: ImgProps["image"];
  @Input() attributes!: ImgProps["attributes"];

  @ViewChild("elRef0") elRef0!: ElementRef;

  _listenerFns = new Map<string, () => void>();

  node_0_img = null;
  node_1_img = null;
  elRef0_state_0 = null;
  setAttributes(el: HTMLElement, value: any, changes?: any) {
    if (!el) {
      return;
    }
    const target = typeof changes === "undefined" ? value : changes;
    Object.keys(target).forEach((key) => {
      if (key.startsWith("on")) {
        if (this._listenerFns.has(key)) {
          this._listenerFns.get(key)!();
        }
        this._listenerFns.set(
          key,
          this.renderer.listen(
            el,
            key.replace("on", "").toLowerCase(),
            target[key]
          )
        );
      } else {
        this.renderer.setAttribute(el, key.toLowerCase(), target[key] ?? "");
      }
    });
  }

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.node_0_img = {
      objectFit: this.backgroundSize || "cover",
      objectPosition: this.backgroundPosition || "center",
    };
    this.node_1_img = (isEditing() && this.imgSrc) || "default-key";
    this.elRef0_state_0 = {};
  }

  ngAfterViewInit() {
    this.setAttributes(this.elRef0?.nativeElement, this.elRef0_state_0);
    this.setAttributes(this.elRef0?.nativeElement, this.attributes);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof window !== "undefined") {
      this.node_0_img = {
        objectFit: this.backgroundSize || "cover",
        objectPosition: this.backgroundPosition || "center",
      };
      this.node_1_img = (isEditing() && this.imgSrc) || "default-key";
      this.elRef0_state_0 = {};
      this.setAttributes(
        this.elRef0?.nativeElement,
        this.elRef0_state_0,
        changes["elRef0_state_0"]?.currentValue
      );
      this.setAttributes(
        this.elRef0?.nativeElement,
        this.attributes,
        changes["attributes"]?.currentValue
      );
    }
  }

  ngOnDestroy() {
    for (const fn of this._listenerFns.values()) {
      fn();
    }
  }
}
