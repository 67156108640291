import { BrandTheme } from './types/brand-theme.interface';

export const defaultTheme: BrandTheme = {
  typography: {
    fonts: {
      primary: {
        family: 'ApercuPro',
        url: '',
        weights: {
          thin: 100,
          ultraLight: 200,
          light: 300,
          regular: 400,
          medium: 500,
          semibold: 600,
          bold: 700,
          black: 900
        }
      },
      secondary: {
        family: 'ApercuPro',
        url: '',
        weights: {
          thin: 100,
          light: 300,
          regular: 400,
          medium: 500,
          semibold: 600,
          bold: 700,
          black: 900
        }
      }
    },
    lineHeights: {
      none: 1,
      tight: 1.25,
      snug: 1.375,
      normal: 1.5,
      relaxed: 1.625,
      loose: 2
    }
  },
  colors: {
    primary: '#2E3F51',
    secondary: '#595f68',
    background: '#ffffff',
    text: {
      primary: '#ffffff',
      secondary: '#ffffff'
    },
    button: {
      primary: '#000000',
      secondary: '#f8f9c5'
    },
    accent: {
      purple: '#845ec2',
      yellow: '#ffc75f',
      pink: '#ff6f91',
      gray: {
        light: '#f5f5f5',
        dark: '#333333'
      }
    }
  },
  favicons: {
    ico: './favicon.ico',
    appleTouchIcon: './apple-touch-icon.png',
    favicon32: './favicon-32x32.png',
    favicon16: './favicon-16x16.png',
    manifest: './site.webmanifest'
  }
};
