import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";

import type { VideoProps } from "./video.types";

@Component({
  selector: "builder-video",
  template: `
    <div [ngStyle]="node_0_div">
      <video
        class="builder-video"
        [attr.preload]="preload || 'metadata'"
        [ngStyle]="node_1_video"
        [attr.src]="video || 'no-src'"
        [attr.poster]="posterImage"
        #elRef0
      >
        <ng-container *ngIf="!lazyLoad">
          <source type="video/mp4" [attr.src]="video" />
        </ng-container>
      </video>
      <ng-container *ngIf="node_2_Show">
        <div [ngStyle]="node_3_div"></div>
      </ng-container>
      <ng-container *ngIf="builderBlock?.children?.length && fitContent">
        <div [ngStyle]="node_4_div"><ng-content></ng-content></div>
      </ng-container>
      <ng-container *ngIf="builderBlock?.children?.length && !fitContent">
        <div [ngStyle]="node_5_div"><ng-content></ng-content></div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export default class BuilderVideo {
  @Input() attributes!: VideoProps["attributes"];
  @Input() fit!: VideoProps["fit"];
  @Input() position!: VideoProps["position"];
  @Input() aspectRatio!: VideoProps["aspectRatio"];
  @Input() fitContent!: VideoProps["fitContent"];
  @Input() builderBlock!: VideoProps["builderBlock"];
  @Input() autoPlay!: VideoProps["autoPlay"];
  @Input() muted!: VideoProps["muted"];
  @Input() controls!: VideoProps["controls"];
  @Input() loop!: VideoProps["loop"];
  @Input() playsInline!: VideoProps["playsInline"];
  @Input() preload!: VideoProps["preload"];
  @Input() video!: VideoProps["video"];
  @Input() posterImage!: VideoProps["posterImage"];
  @Input() lazyLoad!: VideoProps["lazyLoad"];

  @ViewChild("elRef0") elRef0!: ElementRef;

  _listenerFns = new Map<string, () => void>();

  get videoProps() {
    return {
      ...(this.autoPlay === true
        ? {
            autoPlay: true,
          }
        : {}),
      ...(this.muted === true
        ? {
            muted: true,
          }
        : {}),
      ...(this.controls === true
        ? {
            controls: true,
          }
        : {}),
      ...(this.loop === true
        ? {
            loop: true,
          }
        : {}),
      ...(this.playsInline === true
        ? {
            playsInline: true,
          }
        : {}),
    };
  }
  get spreadProps() {
    return {
      ...this.videoProps,
    };
  }
  node_0_div = null;
  node_1_video = null;
  node_2_Show = null;
  node_3_div = null;
  node_4_div = null;
  node_5_div = null;
  setAttributes(el: HTMLElement, value: any, changes?: any) {
    if (!el) {
      return;
    }
    const target = typeof changes === "undefined" ? value : changes;
    Object.keys(target).forEach((key) => {
      if (key.startsWith("on")) {
        if (this._listenerFns.has(key)) {
          this._listenerFns.get(key)!();
        }
        this._listenerFns.set(
          key,
          this.renderer.listen(
            el,
            key.replace("on", "").toLowerCase(),
            target[key]
          )
        );
      } else {
        this.renderer.setAttribute(el, key.toLowerCase(), target[key] ?? "");
      }
    });
  }

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.node_0_div = {
      position: "relative",
    };
    this.node_1_video = {
      width: "100%",
      height: "100%",
      ...this.attributes?.style,
      objectFit: this.fit,
      objectPosition: this.position,
      // Hack to get object fit to work as expected and
      // not have the video overflow
      zIndex: 2,
      borderRadius: "1px",
      ...(this.aspectRatio
        ? {
            position: "absolute",
          }
        : null),
    };
    this.node_2_Show =
      this.aspectRatio &&
      !(this.fitContent && this.builderBlock?.children?.length);
    this.node_3_div = {
      width: "100%",
      paddingTop: this.aspectRatio! * 100 + "%",
      pointerEvents: "none",
      fontSize: "0px",
    };
    this.node_4_div = {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    };
    this.node_5_div = {
      pointerEvents: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
    };
  }

  ngAfterViewInit() {
    this.setAttributes(this.elRef0?.nativeElement, this.spreadProps);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof window !== "undefined") {
      this.node_0_div = {
        position: "relative",
      };
      this.node_1_video = {
        width: "100%",
        height: "100%",
        ...this.attributes?.style,
        objectFit: this.fit,
        objectPosition: this.position,
        // Hack to get object fit to work as expected and
        // not have the video overflow
        zIndex: 2,
        borderRadius: "1px",
        ...(this.aspectRatio
          ? {
              position: "absolute",
            }
          : null),
      };
      this.node_2_Show =
        this.aspectRatio &&
        !(this.fitContent && this.builderBlock?.children?.length);
      this.node_3_div = {
        width: "100%",
        paddingTop: this.aspectRatio! * 100 + "%",
        pointerEvents: "none",
        fontSize: "0px",
      };
      this.node_4_div = {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      };
      this.node_5_div = {
        pointerEvents: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
      };
      this.setAttributes(
        this.elRef0?.nativeElement,
        this.spreadProps,
        changes["spreadProps"]?.currentValue
      );
    }
  }

  ngOnDestroy() {
    for (const fn of this._listenerFns.values()) {
      fn();
    }
  }
}
