<footer class="footer">
  <div class="footer-container">
    <!-- Contact Us Section -->
    <div class="footer-section">
      <h3>CONTACT US</h3>
      <ng-container *ngIf="contactInfo.hours">
        <p>{{ contactInfo.hours }}</p>
      </ng-container>
      <a [href]="'mailto:' + contactInfo.email">{{ contactInfo.email }}</a>
      <ng-container *ngIf="contactInfo.phone?.us">
        <p>US: {{ contactInfo.phone.us }}</p>
      </ng-container>
      <ng-container *ngIf="contactInfo.phone?.canada">
        <p>CANADA: {{ contactInfo.phone.canada }}</p>
      </ng-container>
    </div>

    <!-- Information & FAQ Section -->
    <div class="footer-section" *ngFor="let sectionWrapper of footerSections">
      <h3>{{ sectionWrapper.section.title }}</h3>
      <ul>
        <li *ngFor="let linkWrapper of sectionWrapper.section.links">
          <a [href]="linkWrapper.link.url">{{ linkWrapper.link.title }}</a>
        </li>
      </ul>
    </div>
  </div>

  <!-- Social Media Section -->
  <div class="footer-bottom">
    <div class="social-links">
      <h3>GET SOCIAL WITH US</h3>
      <div class="social-icons">
        <a *ngIf="socialLinks.facebook" [href]="socialLinks.facebook" target="_blank" aria-label="Facebook">
          <fa-icon [icon]="faFacebook"></fa-icon>
        </a>
        <a *ngIf="socialLinks.tiktok" [href]="socialLinks.tiktok" target="_blank" aria-label="TikTok">
          <fa-icon [icon]="faTiktok"></fa-icon>
        </a>
        <a *ngIf="socialLinks.instagram" [href]="socialLinks.instagram" target="_blank" aria-label="Instagram">
          <fa-icon [icon]="faInstagram"></fa-icon>
        </a>
        <a *ngIf="socialLinks.youtube" [href]="socialLinks.youtube" target="_blank" aria-label="YouTube">
          <fa-icon [icon]="faYoutube"></fa-icon>
        </a>
      </div>
    </div>
  </div>
  
  <div class="copyright">
    © {{ brandConfig.currentConfig.brandName }} {{ currentYear }} {{ copyright.tagline }}
  </div>
</footer>
