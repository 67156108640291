import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";

/**
 * This import is used by the Svelte SDK. Do not remove.
 */

export interface FormInputProps {
  type?: string;
  attributes?: any;
  name?: string;
  value?: string;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
}

import { isEditing } from "../../../functions/is-editing";
import { filterAttrs } from "../../helpers";
import { setAttrs } from "../../helpers";

@Component({
  selector: "form-input-component",
  template: `
    <input
      [attr.placeholder]="placeholder"
      [attr.type]="type"
      [attr.name]="name"
      [attr.value]="value"
      [attr.defaultValue]="defaultValue"
      [attr.required]="required"
      #elRef0
    />
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export default class FormInputComponent {
  @Input() defaultValue!: FormInputProps["defaultValue"];
  @Input() attributes!: FormInputProps["attributes"];
  @Input() placeholder!: FormInputProps["placeholder"];
  @Input() type!: FormInputProps["type"];
  @Input() name!: FormInputProps["name"];
  @Input() value!: FormInputProps["value"];
  @Input() required!: FormInputProps["required"];

  @ViewChild("elRef0") elRef0!: ElementRef;

  _listenerFns = new Map<string, () => void>();

  node_0_input = null;
  elRef0_state_0 = null;
  setAttributes(el: HTMLElement, value: any, changes?: any) {
    if (!el) {
      return;
    }
    const target = typeof changes === "undefined" ? value : changes;
    Object.keys(target).forEach((key) => {
      if (key.startsWith("on")) {
        if (this._listenerFns.has(key)) {
          this._listenerFns.get(key)!();
        }
        this._listenerFns.set(
          key,
          this.renderer.listen(
            el,
            key.replace("on", "").toLowerCase(),
            target[key]
          )
        );
      } else {
        this.renderer.setAttribute(el, key.toLowerCase(), target[key] ?? "");
      }
    });
  }

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.node_0_input =
      isEditing() && this.defaultValue ? this.defaultValue : "default-key";
    this.elRef0_state_0 = {};
  }

  ngAfterViewInit() {
    this.setAttributes(this.elRef0?.nativeElement, this.elRef0_state_0);
    this.setAttributes(this.elRef0?.nativeElement, this.attributes);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof window !== "undefined") {
      this.node_0_input =
        isEditing() && this.defaultValue ? this.defaultValue : "default-key";
      this.elRef0_state_0 = {};
      this.setAttributes(
        this.elRef0?.nativeElement,
        this.elRef0_state_0,
        changes["elRef0_state_0"]?.currentValue
      );
      this.setAttributes(
        this.elRef0?.nativeElement,
        this.attributes,
        changes["attributes"]?.currentValue
      );
    }
  }

  ngOnDestroy() {
    for (const fn of this._listenerFns.values()) {
      fn();
    }
  }
}
