import { Component, Input, SimpleChanges } from "@angular/core";
import { CommonModule } from "@angular/common";

import { evaluate } from "../../functions/evaluate/index";
import type { TextProps } from "./text.types";

@Component({
  selector: "builder-text",
  template: `
    <div
      class="builder-text"
      [innerHTML]="processedText"
      [ngStyle]="node_0_div"
    ></div>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export default class BuilderText {
  @Input() builderContext!: TextProps["builderContext"];
  @Input() text!: TextProps["text"];

  get processedText() {
    const context = this.builderContext;
    const {
      context: contextContext,
      localState,
      rootState,
      rootSetState,
    } = context;
    return String(this.text?.toString() || "").replace(
      /{{([^}]+)}}/g,
      (match, group) =>
        evaluate({
          code: group,
          context: contextContext,
          localState,
          rootState,
          rootSetState,
        }) as string
    );
  }
  node_0_div = null;

  ngOnInit() {
    this.node_0_div = {
      outline: "none",
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof window !== "undefined") {
      this.node_0_div = {
        outline: "none",
      };
    }
  }
}
