<div class="container">
  <div class="inner-container">
    <div class="content">
      <div class="column">
        <p>Welcome</p>
        <app-button (click)="onLoginClick($event)">Login</app-button>
      </div>

      <div class="divider"></div>

      <div class="column">
        <p>Don’t have an account?</p>
        <app-button variant="secondary" (click)="onRequestAccessClick($event)">Request Access</app-button>
      </div>
    </div>
  </div>
</div>
