import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BrandConfigService } from '../../services/brand-config.service';

export interface RegistrationData {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  companyType: string;
  companyWebsite: string;
  phoneNumber: string;
  productUsage: string;
  state: string;
}

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private brandConfig: BrandConfigService
  ) {
    this.apiUrl = this.brandConfig.currentConfig.registrationApiUrl;
  }

  submitRegistration(formData: RegistrationData): Observable<any> {
    return this.http.post(this.apiUrl, formData);
  }
} 