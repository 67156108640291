import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { BrandConfigService } from '../../../services/brand-config.service';

@Component({
  selector: 'app-hero',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hero.component.html',
  styleUrl: './hero.component.scss'
})
export class HeroComponent {
  private _title: string = '';
  private _description: string = '';
  private _buttonText: string = '';
  private _backgroundImageUrl: string = '';
  private _buttonUrl: string = '';

  @Input()
  get title(): string {
    return this._title || this.brandConfig.currentConfig.heroTitle;
  }
  set title(value: string) {
    this._title = value;
  }

  @Input()
  get description(): string {
    return this._description || this.brandConfig.currentConfig.heroDescription;
  }
  set description(value: string) {
    this._description = value;
  }

  @Input()
  get buttonText(): string {
    return this._buttonText || this.brandConfig.currentConfig.heroButtonText;
  }
  set buttonText(value: string) {
    this._buttonText = value;
  }

  @Input()
  get backgroundImageUrl(): string {
    return this._backgroundImageUrl || this.brandConfig.currentConfig.heroBackgroundUrl;
  }
  set backgroundImageUrl(value: string) {
    this._backgroundImageUrl = value;
  }

  @Input()
  get buttonUrl(): string {
    return this._buttonUrl || this.brandConfig.currentConfig.heroButtonUrl;
  }
  set buttonUrl(value: string) {
    this._buttonUrl = value;
  }

  constructor(
    private router: Router,
    private brandConfig: BrandConfigService
  ) {}

  navigateToRequestAccess() {
    this.router.navigate([this.buttonUrl]);
  }
}
