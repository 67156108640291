import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";

export type DropzoneProps = BuilderDataProps &
  BuilderComponentsProp & {
    name: string;
    attributes: any;
  };

import Blocks from "../../components/blocks/blocks";
import { deoptSignal } from "../../functions/deopt";
import type { BuilderBlock } from "../../types/builder-block";
import type {
  BuilderComponentsProp,
  BuilderDataProps,
} from "../../types/builder-props";

@Component({
  selector: "builder-slot",
  template: `
    <div [ngStyle]="node_0_div" #elRef0>
      <blocks
        [parent]="node_2_Blocks"
        [path]="node_3_Blocks"
        [context]="builderContext"
        [registeredComponents]="builderComponents"
        [blocks]="node_4_Blocks"
      ></blocks>
    </div>
  `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, Blocks],
})
export default class BuilderSlot {
  @Input() builderContext!: DropzoneProps["builderContext"];
  @Input() name!: DropzoneProps["name"];
  @Input() builderComponents!: DropzoneProps["builderComponents"];

  @ViewChild("elRef0") elRef0!: ElementRef;

  _listenerFns = new Map<string, () => void>();

  node_0_div = null;
  node_1_div = null;
  node_2_Blocks = null;
  node_3_Blocks = null;
  node_4_Blocks = null;
  setAttributes(el: HTMLElement, value: any, changes?: any) {
    if (!el) {
      return;
    }
    const target = typeof changes === "undefined" ? value : changes;
    Object.keys(target).forEach((key) => {
      if (key.startsWith("on")) {
        if (this._listenerFns.has(key)) {
          this._listenerFns.get(key)!();
        }
        this._listenerFns.set(
          key,
          this.renderer.listen(
            el,
            key.replace("on", "").toLowerCase(),
            target[key]
          )
        );
      } else {
        this.renderer.setAttribute(el, key.toLowerCase(), target[key] ?? "");
      }
    });
  }

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.node_0_div = {
      pointerEvents: "auto",
    };
    this.node_1_div = {
      ...(!this.builderContext.context?.symbolId && {
        "builder-slot": this.name,
      }),
    };
    this.node_2_Blocks = this.builderContext.context?.symbolId as string;
    this.node_3_Blocks = `symbol.data.${this.name}`;
    this.node_4_Blocks = this.builderContext.rootState?.[
      this.name
    ] as BuilderBlock[];
  }

  ngAfterViewInit() {
    this.setAttributes(this.elRef0?.nativeElement, this.node_1_div);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof window !== "undefined") {
      this.node_0_div = {
        pointerEvents: "auto",
      };
      this.node_1_div = {
        ...(!this.builderContext.context?.symbolId && {
          "builder-slot": this.name,
        }),
      };
      this.node_2_Blocks = this.builderContext.context?.symbolId as string;
      this.node_3_Blocks = `symbol.data.${this.name}`;
      this.node_4_Blocks = this.builderContext.rootState?.[
        this.name
      ] as BuilderBlock[];
      this.setAttributes(
        this.elRef0?.nativeElement,
        this.node_1_div,
        changes["node_1_div"]?.currentValue
      );
    }
  }

  ngOnDestroy() {
    for (const fn of this._listenerFns.values()) {
      fn();
    }
  }
}
