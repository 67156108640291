<div class="announcement-bar" 
     role="region" 
     aria-label="Announcements" 
     *ngIf="announcements.length > 0"
     (click)="nextAnnouncement()"
     (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()">
  <div class="announcement-bar__container">
    <div class="announcement-bar__content" [@slideAnimation]="currentIndex">
      <p class="announcement-bar__message">
        {{ announcements[currentIndex].text }}
      </p>
    </div>
  </div>
</div>