import { Component, OnInit, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { environment } from '../../../environments/environment';

interface BuilderContextInterface {
  content: any;
  state: any;
}

interface DisplayProduct {
  title: string;
  description?: string;
  imageUrl?: string;
  images?: {
    edges: {
      node: {
        url: string;
      }
    }[];
  };
  metafields?: {
    id: string;
    namespace: string;
    key: string;
    value: string;
  }[];
}

@Component({
  selector: 'app-shopify-collection',
  imports: [CommonModule, NgOptimizedImage],
  standalone: true,
  templateUrl: './shopify-collection.component.html',
  styleUrl: './shopify-collection.component.scss'
})
export class ShopifyCollectionComponent implements OnInit {
  @Input() builderContext!: BuilderContextInterface;
  products: DisplayProduct[] = [];
  apiKey = environment.builderApiKey;

  placeholderProducts: DisplayProduct[] = [
    {
      title: 'Premium Face Serum',
      description: 'HYDRATE • RESTORE • PROTECT',
      imageUrl: '/images/products/placeholder_298x225.png'
    },
    {
      title: 'Advanced Night Cream',
      description: 'REPAIR • RENEW • REJUVENATE',
      imageUrl: '/images/products/placeholder_298x225.png'
    },
    {
      title: 'Vitamin C Brightening Serum',
      description: 'BRIGHTEN • FIRM • GLOW',
      imageUrl: '/images/products/placeholder_298x225.png'
    },
    {
      title: 'Hyaluronic Acid Moisturizer',
      description: 'PLUMP • SMOOTH • NOURISH',
      imageUrl: '/images/products/placeholder_298x225.png'
    },
    {
      title: 'Retinol Night Treatment',
      description: 'RENEW • TRANSFORM • RESTORE',
      imageUrl: '/images/products/placeholder_298x225.png'
    }
  ];

  async ngOnInit() {
    try {
      const collectionHandle = this.builderContext?.content?.data?.state?.collection2?.handle;
      if (collectionHandle) {
        await this.fetchCollectionProducts(collectionHandle);
      } else {
        console.warn('No collection handle found in Builder.io context');
      }
    } catch (error) {
      console.error('Error fetching collection products:', error);
    }
  }

  private async fetchCollectionProducts(handle: string) {
    const query = `
      query {
        collection(handle: "${handle}") {
          id
          handle
          title
          description
          products(first: 5) {
            edges {
              node {
                id
                title
                description
                images(first: 1) {
                  edges {
                    node {
                      url
                    }
                  }
                }
                priceRange {
                  minVariantPrice {
                    amount
                  }
                }
                metafields(identifiers: [{namespace: "custom", key: "b2b_skin_care_features"}]) {
                  id
                  namespace
                  key
                  value
                }
              }
            }
          }
        }
      }
    `;

    try {
      // If Shopify config is missing, we'll just use placeholder products
      if (!environment.shopify?.endpoint || !environment.shopify?.accessToken) {
        console.warn('Shopify configuration is missing - using placeholder products');
        return;
      }

      const response = await fetch(environment.shopify.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Shopify-Storefront-Access-Token': environment.shopify.accessToken
        },
        body: JSON.stringify({ query })
      });

      if (!response.ok) {
        throw new Error('Shopify API request failed');
      }

      const data = await response.json();
      this.products = data.data.collection.products.edges.map((edge: any) => edge.node);
    } catch (error) {
      console.error('Error fetching from Shopify:', error);
      // Don't throw the error, just log it - placeholders will be used
    }
  }

  truncateDescription(description: string, limit: number = 50): string {
    if (!description) return '';
    return description.length > limit 
      ? `${description.substring(0, limit)}...`
      : description;
  }

  getFeatures(value: string | undefined): string[] {
    if (!value) return [];
    try {
      const features = JSON.parse(value);
      return Array.isArray(features) ? features : [];
    } catch {
      return [];
    }
  }

  getProductImage(product: DisplayProduct): string {
    return product.images?.edges[0]?.node?.url || product.imageUrl || '';
  }
}