import {Routes} from '@angular/router';

import {LandingPageComponent} from './pages/landing-page/landing-page.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';

export const routes: Routes = [
  {
    path: 'request-access', component: LandingPageComponent
  },
  {
    path: 'request-successful', component: LandingPageComponent
  },
  {
    path: '404', component: NotFoundComponent
  },
  {
    path: '**', component: LandingPageComponent
  }
];
