<nav class="navbar">
  <div class="navbar-container">
    <button class="mobile-menu-toggle" 
            [class.is-open]="isMobileMenuOpen"
            (click)="toggleMobileMenu()" 
            aria-label="Toggle menu">
      <ng-container *ngIf="!isMobileMenuOpen">
        <span class="hamburger-line"></span>
        <span class="hamburger-line"></span>
        <span class="hamburger-line"></span>
      </ng-container>
      <div *ngIf="isMobileMenuOpen" class="close-icon">
        <span class="close-line"></span>
        <span class="close-line"></span>
      </div>
    </button>

    <a href="/" class="logo-link">
      <img [src]="logo" [alt]="brandName + ' logo'" class="logo" />
    </a>
    
    <div class="nav-links" 
         [class.is-open]="isMobileMenuOpen">
      <div class="mobile-menu-content">
        <a *ngFor="let link of navigationLinks" 
           [href]="link.url" 
           class="nav-link">
          {{ link.text }}
        </a>
      </div>
    </div>
  </div>
</nav>