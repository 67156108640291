import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faFacebookF, 
  faInstagram, 
  faYoutube,
  faTiktok
} from '@fortawesome/free-brands-svg-icons';
import { BrandConfigService } from '../../../services/brand-config.service';
import { environment } from '../../../environments/environment';
import { fetchEntries } from '@builder.io/sdk-angular';
import { getBuilderModels } from '../../constants/builder.constants';

interface FooterSection {
  section: {
    title: string;
    links: {
      link: {
        title: string;
        url: string;
      };
    }[];
  };
}

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterModule, FontAwesomeModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
  faFacebook = faFacebookF;
  faInstagram = faInstagram;
  faYoutube = faYoutube;
  faTiktok = faTiktok;
  private _footerSections: FooterSection[] = [];
  private _contactInfo: any;
  private _socialLinks: any;
  private _copyright: { tagline: string; } | undefined;
  currentYear = new Date().getFullYear();
  hasError: boolean = false;

  constructor(public brandConfig: BrandConfigService) {}

  async ngOnInit() {
    try {
      await this.fetchFooterContent();
    } catch (error) {
      console.error('Error initializing footer:', error);
      this.hasError = true;
    }
  }

  private async fetchFooterContent() {
    try {
      const content = await fetchEntries({
        model: getBuilderModels(this.brandConfig).FOOTER.model,
        apiKey: environment.builderApiKey,
        userAttributes: {
          urlPath: window.location.pathname
        }
      });

      if (content && content.length > 0) {
        const footerContent = content.find(
          (item: any) => item.name === getBuilderModels(this.brandConfig).FOOTER.name
        );
        
        if (footerContent?.data) {
          const data = footerContent.data;
          this._contactInfo = data['contactInfo'] || this.brandConfig.currentConfig.footer.contactInfo;
          this._socialLinks = data['socialLinks'] || this.brandConfig.currentConfig.footer.socialLinks;
          this._copyright = { 
            tagline: data['copyright']?.tagline || this.brandConfig.currentConfig.footer.copyright.tagline 
          };
          this._footerSections = data['sections'] || this.brandConfig.currentConfig.footer.sections;
        }
      }
    } catch (error) {
      console.error('Error fetching footer content:', error);
      this.hasError = true;
    }
  }

  get footerSections(): FooterSection[] {
    return this._footerSections.length > 0 
      ? this._footerSections 
      : this.brandConfig.currentConfig.footer.sections;
  }

  get contactInfo(): any {
    return this._contactInfo || this.brandConfig.currentConfig.footer.contactInfo;
  }

  get socialLinks(): any {
    return this._socialLinks || this.brandConfig.currentConfig.footer.socialLinks;
  }

  get copyright(): { tagline: string; } {
    return {
      tagline: this._copyright?.tagline || this.brandConfig.currentConfig.footer.copyright.tagline
    };
  }
}