import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrandConfigService } from '../../../../services/brand-config.service';
import { Router } from '@angular/router';
import { ButtonComponent } from '../../shared/button/button.component';
import { RegistrationService } from '../../../services/registration.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-registration',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonComponent],
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss',
})
export class RegistrationComponent {
  private _apiUrl: string;
  private _successUrl: string;
  
  get apiUrl(): string {
    return this._apiUrl;
  }
  
  set apiUrl(value: string) {
    this._apiUrl = value;
  }
  
  get successUrl(): string {
    return this._successUrl;
  }
  
  set successUrl(value: string) {
    this._successUrl = value;
  }
  
  submissionError: string | null = null;
  isLoading: boolean = false;
  errors: { [key: string]: string | null } = {};

  constructor(
    private router: Router,
    private registrationService: RegistrationService,
    private brandConfig: BrandConfigService
  ) {
    this._apiUrl = this.brandConfig.currentConfig.registrationApiUrl;
    this._successUrl = this.brandConfig.currentConfig.registrationSuccessUrl;
    this.brandName = this.brandConfig.currentConfig.brandName;
  }

  brandName: string;
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  companyName: string = '';
  companyType: string = '';
  companyWebsite: string = '';
  companyTypes: string[] = [
    'Specialty Boutique',
    'Multi-Location Retailer',
    'Online Store',
    'Medical Spa',
    'Independent Esthetician',
    'Dermatologist',
    'Spa/Treatment Center',
    'Salon',
    'Other',
  ];
  phoneNumber: string = '';
  title: string = '';
  titleError: string | null = null;

  firstNameError: string | null = null;
  lastNameError: string | null = null;
  emailError: string | null = null;
  companyNameError: string = '';
  companyTypeError: string = '';
  companyWebsiteError: string = '';
  phoneNumberError: string = '';

  productUsage: string = '';
  productUsageError: string | null = null;
  productUsageTypes: string[] = [
    'I am reselling them in my store',
    'I am providing them to customers as a complementary service',
    'I am using them for my own business needs'
  ];
  isReseller: boolean = false;

  stateOptions: string[] = [
    'Alabama', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
    'Delaware', 'Florida', 'Georgia', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
    'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts',
    'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska',
    'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
    'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
    'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
    'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
    'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  state: string = '';
  stateError: string = '';

  otherCompanyType: string = '';

  validateEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  async handleSubmit(event: Event): Promise<void> {
    event.preventDefault();
    
    if (this.isLoading) return;
    
    this.resetErrors();
    
    let hasError = false;
    hasError = this.validateForm();

    if (!hasError) {
      this.isLoading = true;
      try {
        const formData = this.getFormData();
        const response = await firstValueFrom(
          this.registrationService.submitRegistration(formData)
        );

        if (response) {
          await this.router.navigate([this.successUrl]);
        }
      } catch (error: any) {
        this.submissionError = 'There was an error submitting your registration. Please try again later.';
        throw error;
      } finally {
        this.isLoading = false;
      }
    }
  }

  private resetErrors(): void {
    this.firstNameError = null;
    this.lastNameError = null;
    this.emailError = null;
    this.companyNameError = '';
    this.companyTypeError = '';
    this.companyWebsiteError = '';
    this.phoneNumberError = '';
    this.titleError = null;
    this.productUsageError = null;
    this.stateError = '';
    this.errors = {};
  }

  private validateForm(): boolean {
    let hasError = false;
    
    // Required fields validation
    const requiredFields = [
      { field: 'firstName', message: 'Please enter your first name' },
      { field: 'lastName', message: 'Please enter your last name' },
      { field: 'email', message: 'Please enter your email address' },
      { field: 'companyName', message: 'Please enter your company name' },
      { field: 'companyType', message: 'Please select your company type' },
      { field: 'companyWebsite', message: 'Please enter your company website' },
      { field: 'phoneNumber', message: 'Please enter your phone number' },
      { field: 'title', message: 'Please enter your title' },
      { field: 'productUsage', message: 'Please select how you plan to use LOLA products' },
      { field: 'state', message: 'Please select your state' }
    ];

    requiredFields.forEach(({ field, message }) => {
      if (!this[field as keyof RegistrationComponent]) {
        this.errors[field] = message;
        (this as any)[`${field}Error`] = message;
        hasError = true;
      }
    });

    // Validate otherCompanyType if "Other" is selected
    if (this.companyType === 'Other' && !this.otherCompanyType) {
      this.errors['otherCompanyType'] = 'Please specify your company type';
      hasError = true;
    }

    // Format-specific validations
    if (this.email && !this.validateEmail(this.email)) {
      this.errors['email'] = 'Please enter a valid email address';
      this.emailError = 'Please enter a valid email address';
      hasError = true;
    }

    if (this.companyWebsite && !this.companyWebsite.startsWith('http://') && !this.companyWebsite.startsWith('https://')) {
      this.errors['companyWebsite'] = 'Please enter a valid website URL starting with http:// or https://';
      this.companyWebsiteError = 'Please enter a valid website URL starting with http:// or https://';
      hasError = true;
    }

    if (this.state && !this.stateOptions.includes(this.state)) {
      this.errors['state'] = 'Please select a valid US state';
      this.stateError = 'Please select a valid US state';
      hasError = true;
    }

    return hasError;
  }

  private getFormData() {
    return {
      title: this.title,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      companyName: this.companyName,
      companyType: this.companyType === 'Other' ? this.otherCompanyType : this.companyType,
      companyWebsite: this.companyWebsite,
      phoneNumber: this.phoneNumber,
      productUsage: this.productUsage,
      state: this.state
    };
  }

  onProductUsageChange(): void {
    this.isReseller = this.productUsage === 'I am reselling them in my store';
  }
}