import { BrandConfig } from './types/brand-config.interface';

export const defaultConfig: BrandConfig = {
  siteTitle: 'B2B Wholesale LOLA',
  brandName: 'B2B LOLA',
  brandLogoUrl: '/images/logos/lola-logo-vector.png',
  loginShopifyUrl: 'http://store.business.mylola.com',
  requestAccessUrl: '/request-access',
  registrationApiUrl: 'https://h65h2jtrdck6hcrupvworisjxq0yjopy.lambda-url.us-west-2.on.aws/',
  heroBackgroundUrl: '/images/hero-background.jpg',
  heroTitle: 'Welcome to Our Wholesale Platform',
  heroDescription: 'Join our network of trusted distributors and gain access to exclusive wholesale pricing, comprehensive business support, and premium products.',
  heroButtonText: 'BECOME A PARTNER',
  heroButtonUrl: '/',
  manifest: {
    name: 'B2B LOLA Wholesale',
    shortName: 'B2B LOLA',
    themeColor: '#2E3F51',
    backgroundColor: '#ffffff',
    icons: {
      android192: '/icons/android-chrome-192x192.png',
      android512: '/icons/android-chrome-512x512.png'
    }
  },
  builderModels: {
    announcements: {
      model: 'announcements',
      name: 'B2B Lola Announcements',
      dataKey: 'announcements'
    },
    footer: {
      model: 'footer-links',
      name: 'lola-footer',
    }
  },
  footer: {
    sections: [
      {
        section: {
          title: 'INFORMATION',
          links: [
            {
              link: {
                title: 'Become a Partner',
                url: '/request-access'
              }
            }
          ]
        }
      }
    ],
    contactInfo: {
      hours: 'Business Hours: 9AM - 5PM EST',
      email: 'contact@example.com',
      phone: {
        us: '+1-800-XXX-XXXX',
        canada: '+1-800-XXX-XXXX'
      }
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/lola',
      tiktok: 'https://www.tiktok.com/@lolaofficial',
      instagram: 'https://www.instagram.com/lola/',
    },
    copyright: {
      tagline: 'All Rights Reserved'
    }
  },
  wholesaleImage: '/images/cta.jpg',
  wholesaleTitle: 'Wholesale Partnership',
  wholesaleDescription: 'Join our network of trusted distributors and gain access to exclusive wholesale pricing, comprehensive business support, and premium products.',
  wholesaleButtonText: 'APPLY NOW',
  wholesaleButtonUrl: '/request-access',
  wholesaleHighlights: [
    { highlight: '100% Organic Cotton Tampons' },
    { highlight: 'Ultra-Thin Pads with Wings' },
    { highlight: 'Organic Cotton Liners' },
  ],
  wholesaleHighlightsTitle: 'Best-Selling Customer Favorites',
  registrationSuccessUrl: '/request-successful',
};
