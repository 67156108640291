import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnouncementBarComponent } from '../../components/announcement-bar/announcement-bar.component';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [
    CommonModule,
    AnnouncementBarComponent,
    NavbarComponent,
    FooterComponent,
    RouterModule
  ],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})
export class NotFoundComponent {}
