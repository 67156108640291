import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonComponent } from '../shared/button/button.component';
import { BrandConfigService } from '../../../services/brand-config.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  private _loginUrl: string = '';
  private _requestAccessUrl: string = '';

  @Input()
  get loginUrl(): string {
    return this._loginUrl || this.brandConfig.currentConfig.loginShopifyUrl;
  }
  set loginUrl(value: string) {
    this._loginUrl = value;
  }

  @Input()
  get requestAccessUrl(): string {
    return this._requestAccessUrl || this.brandConfig.currentConfig.requestAccessUrl;
  }
  set requestAccessUrl(value: string) {
    this._requestAccessUrl = value;
  }
  
  constructor(
    private router: Router,
    private brandConfig: BrandConfigService
  ) {}

  async onLoginClick(event: void) {
    window.location.href = this.loginUrl;
  }

  async onRequestAccessClick(event: void) {
    return await this.router.navigate([this.requestAccessUrl]);
  }
}
